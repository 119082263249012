"use client";

import { Crisp } from "crisp-sdk-web";
import { useEffect } from "react";
import { Env } from "@/lib/Env.mjs";

const CrispChat = () => {
  useEffect(() => {
    if (Env.NEXT_PUBLIC_CRISP_ID) {
      Crisp.configure(Env.NEXT_PUBLIC_CRISP_ID);
    }
  }, []);

  return null;
};

export default CrispChat;
